import Carousel from 'react-bootstrap/Carousel';

function SliderGallery() {
  return (
    <section className="gallery-section" id="gallery">
        <div className="container" style={{justifyContent:'center', paddingTop:'3%', paddingBottom:'4%', alignContent:'center'}}>
            <h1 className="slide-right">                        
                <span>
                    Nuestra
                </span>
                &nbsp;Galeria
            </h1>
        </div>
        <Carousel indicators={false} className="custom-carousel">
        <Carousel.Item>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 container-gallery">
                        <img src="images/img-gallery1.jpg" alt="..."/>
                    </div>
                    <div className="col-md-6 container-gallery">
                        <img src="images/img-gallery2.jpg" alt="..."/>
                    </div>                    
                </div>                
            </div>           
        </Carousel.Item>
        <Carousel.Item>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 container-gallery">
                        <img src="images/img-gallery3.jpg" alt="..."/>
                    </div>
                    <div className="col-md-6 container-gallery">
                        <img src="images/img-gallery4.jpg" alt="..."/>
                    </div>                    
                </div>                
            </div>          
        </Carousel.Item>
        <Carousel.Item>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 container-gallery">
                        <img src="images/fisica1.jpg" alt="..."/>
                    </div>
                    <div className="col-md-6 container-gallery">
                        <img src="images/img-gallery6.jpg" alt="..."/>
                    </div>                    
                </div>                
            </div>            
        </Carousel.Item>
        </Carousel>
    </section>
  );
}

export default SliderGallery;