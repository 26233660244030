import React, { useState, useEffect } from 'react';
import useFetchData from '../hooks/useFetchData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const MedicalAppointmentSection = () => {
  // Estado para manejar los campos del formulario
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    date: '',
    message: ''
  });

  const [submitData, setSubmitData] = useState(null); // Estado para controlar el envío

  // Estado para manejar el hook
  const { responseData, loading, error } = useFetchData(submitData);

  // Función para obtener la fecha actual en el formato 'YYYY-MM-DD'
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };


  // Maneja el cambio en los campos del formulario
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Maneja el envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    // Validar email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      alert('Por favor, introduce un email válido.');
      return;
    }

    if (formData.phone === "") {
      alert('Por favor, introduce un número de teléfono.');
      return;
    }

    // Validar teléfono (no negativo)
    if (formData.phone < 0) {
      alert('Por favor, introduce un número de teléfono válido.');
      return;
    }

    if (formData.name === "") {
      alert('Por favor, introduce tu nombre.');
      return;
    }

    if (formData.date === "") {
      alert('Por favor, selecciona una fecha.');
      return;
    }

    if (formData.message === "") {
      alert('Por favor, introduce el tipo de servicio para el que quieres agendar la cita.');
      return;
    }   

    // Validar fecha (debe ser mayor a la de hoy)
    const selectedDate = new Date(formData.date);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Eliminar la hora para comparación
    if (selectedDate <= currentDate) {
      alert('Por favor, selecciona una fecha futura.');
      return;
    }

    // Si todas las validaciones pasan
    const emailData = {
      from: formData.email,
      to: 'fundacionmananpasto@gmail.com',
      subject: `Cita para ${formData.name}`,
      body: `Fecha: ${formData.date}\nTeléfono: ${formData.phone}\nMensaje: ${formData.message}`
    };
    setSubmitData(emailData);
  };

  // Limpia el formulario si se recibe respuesta
  useEffect(() => {
    if (responseData) {
      setFormData({
        name: '',
        email: '',
        phone: '',
        date: '',
        message: ''
      });
    }
  }, [responseData]);

  return (
    <section className="medical_appointment" id="medical_appointment">
      <div className="form-container">
        <div className="row">
          <div className='col-md-6 image'>
            <img src="images/simbolo.png" alt="..." style={{ paddingRight: '22%' }} />
          </div>
          <div className="col-md-6" style={{ padding: '7% 1%' }}>
            <form className='form-cita' onSubmit={handleSubmit}>
              <div className="text-center">
              <h2 className="custom_heading">    
                Agende su          
                <span>
                  &nbsp;Cita
                </span>
                  
              </h2>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <input
                    type="text"
                    name="name"
                    placeholder="Nombre Completo"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className='col-md-6'>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <input
                    type="number"
                    name="phone"
                    placeholder="Teléfono"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </div>
                <div className='col-md-6'>
                  <input
                    type="date"
                    name="date"
                    placeholder="Fecha"
                    value={formData.date}
                    min={getCurrentDate()} // Desactiva fechas anteriores
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div>
                <input
                  type="text"
                  className="message-box"
                  name="message"
                  placeholder="Mensaje"
                  value={formData.message}
                  onChange={handleChange}
                />
              </div>
              <div className="d-flex justify-content-center">
                <button type="submit">
                  ENVIAR
                </button>
              </div>
            </form>
            {/* Mostrar estado de carga o errores */}
            {loading && (
              <div className="loading-icon">
                <FontAwesomeIcon icon={faSpinner} spin /> Enviando...
              </div>
            )}
            {error && (
              <div className="error-icon">
                <FontAwesomeIcon icon={faExclamationCircle} /> Error en el envío
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MedicalAppointmentSection;