function Footer() {
  return (
    <>
      <section className="footer_section">
        <p>
          Copyright © 2024 Fundación Manan M&M Todos los derechos reservados.
        </p>
      </section>
    </>
  );
}

export default Footer;
