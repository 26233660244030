import React, { useState, useEffect } from 'react';
import LinkItem from './LinkItem';

function Header() {
  const [activeIndex, setActiveIndex] = useState(0); // Estado para mantener el índice activo
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true); // Estado para manejar el colapso del navbar

  // Función para manejar el clic en un enlace
  const handleLinkClick = (index) => {
    setActiveIndex(index);
    setIsNavbarCollapsed(true); // Cerrar el navbar al hacer clic en un enlace
  };

  // Función para manejar el toggle del navbar
  const toggleNavbar = () => {
    setIsNavbarCollapsed(!isNavbarCollapsed);
  };

  // Lista de enlaces
  const links = [
    { title: 'Inicio', href: '#home' },
    { title: 'Acerca de', href: '#about' },
    { title: 'Servicios', href: '#service' },
    { title: 'Galeria', href: '#gallery' },
    { title: 'Citas', href: '#medical_appointment' },
    { title: 'Horarios', href: '#schedule' }
  ];

  useEffect(() => {
    // Activar el primer enlace por defecto al cargar la lista
    setActiveIndex(0);
  }, []); // Se ejecuta solo una vez al montar el componente

  return (
    <>
      <header className="header_section">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg custom_nav-container">
            <a className="navbar-brand" href="index.html">
              <img src="images/logo-manan.png" alt="..." className="logo" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              aria-controls="navbarSupportedContent"
              aria-expanded={!isNavbarCollapsed}
              aria-label="Toggle navigation"
              onClick={toggleNavbar}
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className={`collapse navbar-collapse ${isNavbarCollapsed ? '' : 'show'}`}
              id="navbarSupportedContent"
            >
              <div className="d-flex mx-auto flex-column flex-lg-row align-items-center">
                <ul className="navbar-nav">
                  {links.map((link, index) => (
                    <LinkItem
                      key={index}
                      title={link.title}
                      href={link.href}
                      isActive={index === activeIndex}
                      handleClick={() => handleLinkClick(index)}
                    />
                  ))}
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}

export default Header;