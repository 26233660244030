import axios from 'axios';
import config from '../config';

export const sendData = async (data) => {
  try {
    const response = await axios.post(config.apiUrl, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error sending data:', error);
    throw new Error('Error sending data');
  }
};