import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Slider from './components/Slider';
import { FloatingWhatsApp } from 'react-floating-whatsapp'

import './App.css';



function App() {
  return (
    <div className="App">
      
      <Header />
      <Slider />
      
      <Home />
      <Footer />
      <FloatingWhatsApp phoneNumber='573118429388' accountName='Fundación Manan M&M' chatMessage='Hola! Como podemos ayudarte? ' statusMessage='' avatar="/images/logo-fondo.jpg" placeholder='Escribe un mensaje'/>

    </div>
  );
}

export default App;
