const LinkItem = ({ title, href, isActive, handleClick }) => {
  return (
    <li>
      <a href={href} className={isActive ? 'nav-link active-item' : 'nav-link'} onClick={handleClick}>
        {title}
      </a>
    </li>
  );
};

export default LinkItem;