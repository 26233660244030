import { useState, useEffect } from 'react';
import { sendData } from '../services/apiService'; // Ajusta la ruta según tu estructura de carpetas

const useFetchData = (data) => {
  const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (data) {
        setLoading(true);
        try {
          const result = await sendData(data);
          setResponseData(result);
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [data]); // Dependencia en `data` para ejecutar solo cuando cambie

  return { responseData, loading, error };
};

export default useFetchData;